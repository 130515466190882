@import url('../../Layout/Header/index.less');
@import url('../../index.less');

.home {
  .size(100%);
  &-1 {
    .size(100%, 700px);
    .background('https://s.huiyunban.cn/1742393305661968384.png');
    display: flex;
    justify-content: center;
  }
  &-carousel {
    .size(1200px, 700px);
    position: relative;
    &-item {
      text-align: justify;
      .size(800px);
      position: relative;
      left: 0;
      top: 25%;
      &-title {
        &::before {
          content: '';
          display: inline-block;
          .size(6px, 38px);
          background: #FF8437;
          position: absolute;
          top: 23px;
        }
        span {
          margin-left: 20px;
          .font-size-minx(PingFang SC,#303133,40px, 500);
        }
      }
      &-row {
        .font-size-minx(PingFang SC,#303133,50px, 500);
      }
      &-spot {
        .size(100%, 45px);
      }
      &-button{
        .nomal-button()
      }
      &-ai {
        color: #0256FF !important;
      }
    }
  }
  .ant-carousel .slick-dots {
    width: 190px;
    top: 420px;
    height: 35px;
  }
  .ant-carousel .slick-dots li {
    width: 54px;
    background: rgba(0, 0, 0, 0.2);
    height: 8px;
  }
  .ant-carousel .slick-dots li button {
    height: 100%;
    border-radius: 0;
  }
  .ant-carousel .slick-dots li.slick-active {
    background: rgba(0, 0, 0, 0.5);
  }
  .ant-carousel .slick-dots li.slick-active button {
    background: rgba(0, 0, 0, 0.5);
  }
  &-2 {
    .number-base(100%, 734px, 89px, #fff);
    &-h1 {
      .font-size-minx(AlibabaPuHuiTi,#555,28px, 500);
      margin-bottom: 4px;
    }
    &-h2 {
      .font-size-minx(AlibabaPuHuiTi,#333,40px, 500)
    }
    &-content {
      .size(1200px);
      position: relative;
      left:  calc(50% - 600px);
      display: flex;
      justify-content: space-around;
      margin-top: 68px;
      &-item {
        .size(332px, 376px);
        &:nth-child(1) {
          .background('https://s.huiyunban.cn/1744655516832894976.png');
        }
        &:nth-child(2) {
          .background('https://s.huiyunban.cn/1744655871524212736.png')
        }
        &:nth-child(3) {
          .background('https://s.huiyunban.cn/1744656056593682432.png')
        }
        &-h1 {
          .font-size-minx('思源黑体',#333,26px, normal);
          margin: 229px 0 14px 0;
        }
        &-h2 {
          .font-size-minx('思源黑体',#333,20px, normal)
        }
      }
    }
    img {
      width: 1200px
    }
  }
  &-3 {
    .number-base(100%, 657px, 86px, rgba(246, 249, 255, 0.502));
    &-h1 {
      .font-size-minx(AlibabaPuHuiTi,#555,28px, 500);
      margin-bottom: 4px;
    }
    &-h2 {
      .font-size-minx(AlibabaPuHuiTi,#333,40px, 500)
    }
    &-content {
      margin-top: 90px;
      .content-base(297px,flex, space-between);
      &-item {
        padding: 163px 16px 16px 16px;
        box-sizing: border-box;
        .size(276px, 297px);
        box-shadow: 0px 6px 14px 0px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        &:nth-child(1) {
          .background('https://s.huiyunban.cn/1742455065802706944.png')
        }
        &:nth-child(2) {
          .background('https://s.huiyunban.cn/1742483522414710784.png')
        }
        &:nth-child(3) {
          .background('https://s.huiyunban.cn/1742483780741894144.png')
        }
        &:nth-child(4) {
          .background('https://s.huiyunban.cn/1742483943141150720.png')
        }
        &-h1 {
          .font-size-minx(AlibabaPuHuiTi,#333,20px, 500);
          margin-bottom: 20px;
        }
        &-col {
          display: inline-block;
          text-align: justify;
          margin-bottom: 17px;
          .font-size-minx(AlibabaPuHuiTi,#555,14px, normal);
          &-dot {
            .size(6px, 6px);
            display: inline-block;
            background-color: #006EFF;
            border-radius: 50%;
            margin: 0 4px 2px 0;
          }
        }
      }
    }
  }
  &-4 {
    .number-base(100%, 269px, 0px, #EFF4FF);
    &-content {
      .content-base(269px,flex, space-between);
      &-item {
        .size(276px, 100%);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        &-n {
          .font-size-minx(DIN,#0256FF,70px, 700);
        }
        &-r {
          .font-size-minx(AlibabaPuHuiTi,#333,30px, 700);
        }
        &-b {
          .font-size-minx(normal,#333,20px, normal);
        }
      }
    }
  }
}