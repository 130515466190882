
@import url('../../index.less');
@import url('../../Layout/Header/index.less');

.product {
  .size(100%);
  &-1 {
    .number-base(100%, 375px, 119px);
    .background('https://s.huiyunban.cn/1742855338136178688.png');
    &-content {
      &-item {
        &:nth-child(1) {
          .font-size-minx(AlibabaPuHuiTi,#fff,30px, normal);
          margin-bottom: 38px;
        }
        &:nth-child(2) {
          .font-size-minx(AlibabaPuHuiTi,#fff,50px, 500);
        }
      }
    }
  }
  &-2 {
    .number-base(100%, 620px, 121px);
    &-content {
      .content-base(auto,block, center);
      position: relative;
      min-height: 417px;
      &-item {
        display: inline-block;
        text-align: justify;
        // .size(50%);
        position: absolute;
        vertical-align: text-top;
        &-1 {
          .size(54px, 4px);
          border-radius: 145px;
          background: linear-gradient(270deg, #1AEA77 0%, #0FD27A 100%);
        }
        &-2 {
          .font-size-minx(AlibabaPuHuiTi,#333,30px, 500);
          margin: 39px 0 36px 0;
        }
        &-3 {
          &-dot {
            .size(6px, 6px);
            display: inline-block;
            background-color: #006EFF;
            margin: 0 12px 2px 0;
          }
          margin-bottom: 30px;
          .font-size-minx(AlibabaPuHuiTi,#444, 18px, normal);
        }
        &-4 {
          .nomal-button()
        }
      }
    }
  }
  &-3 {
    .number-base(100%, auto, 100px, #fff);
    &-h1 {
      .font-size-minx(AlibabaPuHuiTi,#3D3D3D, 40px, 500);
      margin-bottom: 73px;
    }
    &-content {
      .content-base(auto,flex, space-between);
      &-item {
        padding: 46px 37px 0 38px;
        box-sizing: border-box;
        .size(384px, 210px);
        position: relative;
        img {
          .size(61px, 51px);
          position: absolute;
          right: 0;
          top: 32px;
        }
        box-shadow: 0px 6px 14px 0px rgba(0, 0, 0, 0.1);
        &-title {
          text-align: justify;
          .font-size-minx(AlibabaPuHuiTi,#333, 24px, 500);
        }
        &-content {
          text-align: justify;
          margin-top: 16px;
          .font-size-minx(AlibabaPuHuiTi,#444, 16px, normal);
        }
        &:nth-child(-n+3) {
          margin-bottom: 24px;
        }
      }
    }
  }
  &-4 {
    .number-base(100%, auto, 144px, #fff);
    &-h1 {
      .font-size-minx(AlibabaPuHuiTi,#555, 20px, 500);
    }
    &-h2 {
      .font-size-minx(AlibabaPuHuiTi,#3D3D3D, 40px, 500);
      margin: 10px 0 83px 0;
    }
    &-content {
      margin-bottom: 169px;
     }
  }
  &-5{
    .number-base(100%, auto, 113px, #fff);
    margin-bottom: 100px;
    &-h1 {
      .font-size-minx(AlibabaPuHuiTi,#3D3D3D, 40px, 500);
      margin-bottom: 80px;
      &-span {
        color: #0256FF !important;
      }
    }
    &-content {
      .content-base(auto,flex, space-between);
      &-item {
        .size(260px, 206px);
        padding: 29px 18px 0 18px;
        box-sizing: border-box;
        box-shadow: 0px 6px 14px 0px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        &-title {
          .font-size-minx(AlibabaPuHuiTi,#333, 20px, 500);
          margin-bottom: 14px;
        }
        &-content {
          .font-size-minx(AlibabaPuHuiTi,#444, 16px, normal);
        }
      }
      img {
        .size(40px, 40px);
        margin-top: 83px;
      }
    }
  }
  &-6 {
    .number-base(100%, 498px, 80px, #fff);
    &-content {
      .content-base(auto,flex, space-between);
      position: relative;
      &-item {
        .size(50%);
        text-align: justify;
        position: absolute;
        &-h1 {
          .font-size-minx(AlibabaPuHuiTi,#333, 20px, normal);
          margin-bottom: 39px;
          &::after {
            content: '';
            display: inline-block;
            .size(54px, 4px);
            background: linear-gradient(270deg, #1AEA77 0%, #0FD27A 100%);
            position: absolute;
            top: 13px;
            margin-left: 5px;
            border-radius: 145px;
          }
        }
        &-h2 {
          .font-size-minx(AlibabaPuHuiTi,#333, 30px, 500);
          margin: 28px 0 46px 0;
        }
        &-content {
          .size(100%);
          &-item {
            .size(196px, 200px);
            display: inline-block;
            vertical-align: middle;
            padding: 30px 19px 0 19px;
            box-sizing: border-box;
            position: relative;
            box-shadow: 0px 6px 14px 0px rgba(0, 0, 0, 0.1);
            margin-right: 22px;
            background: #fff;
            &-title {
              text-align: center;
              .font-size-minx(AlibabaPuHuiTi,#333, 18px, 500);
              margin-bottom: 16px;
            }
            &-text{
              .font-size-minx(AlibabaPuHuiTi,#555, 16px, normal);
            }
            img {
              width: 22px !important;
              position: absolute;
              bottom: 0;
            }
            
          }
        }
        img {
          .size(441px);
          position: absolute;
        }
      }
    }
  }
  &-7 {
    .number-base(100%, 705px, 80px, #fff);
    &-h1 {
      .font-size-minx(AlibabaPuHuiTi,#333, 20px, normal);
    }
    &-h2 {
      .font-size-minx(AlibabaPuHuiTi,#333, 40px, 500);
    }
    &-content {
      .content-base(auto,flex, space-between);
      margin-top: 85px;
      &-item {
        .size(384px, 306px);
        // background: #122E67;
        &:nth-child(1) {
          .background('https://s.huiyunban.cn/1744286023921176576.png')
        }
        &:nth-child(2) {
          .background('https://s.huiyunban.cn/1744286937495113728.png')
        }
        &:nth-child(3) {
          .background('https://s.huiyunban.cn/1744287094051704832.png')
        }
        position: relative;
       &-content {
        .size(334px, 122px);
        background: #fff;
        position: absolute;
        left: 25px;
        bottom: -70px;
        padding: 26px 28px 0 28px;
        box-sizing: border-box;
        border-radius: 10px;
        box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.1);
        &-title {
          .font-size-minx(AlibabaPuHuiTi,#555, 16px, normal);
        }
        &-text {
          .font-size-minx(AlibabaPuHuiTi,#333, 20px, 500);
          margin-top: 19px;
        }
       }
      } 
    }

  }
}