@import url('../../index.less');

.nomal-button(@width: 160px) {
    .size(@width, 50px);
    border-radius: 4px;
    text-align: center;
    line-height: 50px;
    .font-size-minx(nomal, #fff, 20px, 500);
    background: linear-gradient(270deg, #0075FF 0%, #003DFF 98%);
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
}

.header {
    width: 100%;
    height: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        width: 211px;
        height: 47px;
        margin-right: 56px;
    }
    &-router {
        margin: 0 14% 0 4%;
    }
    &-show {
        margin-left: 173px;
       .nomal-button()
    }
    &-nav {
        span {
            display: inline-block;
            .size(100px, 50px);
            line-height: 50px;
            .font-size-minx(AlibabaPuHuiTi,#333,22px, 500);
            margin-right: 20px;
            cursor: pointer;
        }
        &-active {
            color: #0256FF !important;
        }
    }
    .ant-tabs-tab-btn {
        font-size: 18px;
    }
}

.ant-tabs .ant-tabs-tab {
    width: 100px;
    justify-content: center;
}

.ant-dropdown .ant-dropdown-menu {
    padding: 20px;
    box-shadow: none;
     border-radius: 0;
}
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
    padding: 10px;
    font-size: 18px;
}