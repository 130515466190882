@import url('../../index.less');
@import url('../../Layout/Header/index.less');

.service {
  .size(100%);
  &-1 {
    .number-base(100%, 375px, 119px);
    .background('https://s.huiyunban.cn/1742855338136178688.png');
    &-content {
      .content-base(auto, block, center);
      &-item {
        &:nth-child(1) {
          .font-size-minx(AlibabaPuHuiTi, #fff, 30px, normal);
          margin-bottom: 38px;
        }

        &:nth-child(2) {
          .font-size-minx(AlibabaPuHuiTi, #fff, 50px, 500);
        }
      }
    }
  }
  &-2 {
    .number-base(100%, 498px, 80px, #fff);
    &-content {
      .content-base(auto,flex, space-between);
      position: relative;
      &-item {
        .size(50%);
        text-align: justify;
        position: absolute;
        &-h1 {
          .font-size-minx(AlibabaPuHuiTi,#333, 30px, 500);
          &::after {
            content: '';
            display: inline-block;
            .size(54px, 4px);
            background: linear-gradient(270deg, #1aea77 0%, #0fd27a 100%);
            position: absolute;
            top: 20px;
            margin-left: 5px;
            border-radius: 145px;
          }
          margin-bottom: 46px;
        }
        &-li {
          .size(100%);
          .font-size-minx(AlibabaPuHuiTi,#333, 18px, normal);
          margin-bottom: 30px;
          &-dot {
            .size(6px, 6px);
            display: inline-block;
            background-color: #006eff;
            // border-radius: 50%;
            margin: 0 12px 2px 0;
          }
        }
        img {
          .size(456px);
          position: absolute;
        }
      }
    }
  }
  .case-wrap {
    background: #f6f9ff;
    padding: 80px 0 60px;
    .case-block-title {
      margin-top: 0;
      margin-bottom: 60px;
      font-size: 40px;
      font-weight: 500;
      line-height: 50px;
      color: #303133;
    }
    .case-list {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;
    }

    .case-header {
      display: flex;
      align-items: center;
    }

    .case-logo {
      width: 48px;
      height: 48px;
    }
    .case-name {
      margin-left: 18px;
      font-size: 20px;
      font-weight: 500;
      line-height: 23px;
      color: #303133;
    }

    .case-item {
      display: flex;
      width: 350px;
      padding: 20px;
      align-items: flex-start;
      flex-direction: column;
      border: 1px solid #cadcff;
      border-radius: 10px;
      background: #fff;
      text-align: left;
      box-shadow: 0px 4px 10px 0px rgba(2, 86, 255, 0.15);
    }

    .case-item-title {
      margin-top: 20px;
      margin-bottom: 4px;
      font-size: 18px;
      font-weight: 500;
      line-height: 30px;
      color: #303133;
    }
    .case-item-content {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #303133;
    }

    .tips {
      color: #0256ff;
      font-weight: 500;
    }
  }

  .brand-list {
    margin: 50px auto 58px;
    display: flex;
    text-align: center;
    width: 1200px;
    gap: 23px 72px;
    flex-wrap: wrap;
  }
  .brand-item {
    flex: 0 0 140px;
    img {
      display: block;
      width: 140px;
      height: 100px;
      border-radius: 12px;
      box-shadow: 0px 4px 10px 0px rgba(2, 86, 255, 0.15);
    }
  }
  .case-desc {
    font-weight: 500;
    font-size: 40px;
    color: #303133;
    .tips {
      color: #0256ff;
      font-weight: 500;
    }
  }
}
