@import url('../../index.less');
@import url('../../Layout/Header/index.less');

.my {
  .size(100%);
  &-1 {
    .number-base(100%, 375px, 119px);
    .background('https://s.huiyunban.cn/1742855338136178688.png');
    &-content {
      .content-base(auto, block, center);
      &-item {
        &:nth-child(1) {
          .font-size-minx(AlibabaPuHuiTi, #fff, 30px, normal);
          margin-bottom: 38px;
        }

        &:nth-child(2) {
          .font-size-minx(AlibabaPuHuiTi, #fff, 50px, 500);
        }
      }
    }
  }
  &-2 {
    .number-base(100%, 498px, 80px, #fff);
    &-content {
      .content-base(auto,flex, space-between);
      position: relative;
      &-item {
        .size(50%);
        text-align: justify;
        position: absolute;
        &-h1 {
          .font-size-minx(AlibabaPuHuiTi,#333, 30px, 500);
          &::after {
            content: '';
            display: inline-block;
            .size(54px, 4px);
            background: linear-gradient(270deg, #1AEA77 0%, #0FD27A 100%);
            position: absolute;
            top: 20px;
            margin-left: 5px;
            border-radius: 145px;
          }
          margin-bottom: 46px;
        }
        &-h2 {
          .font-size-minx(AlibabaPuHuiTi,#555, 18px, normal);
          line-height: 30px;
        }
        &-li {
          .size(100%);
          .font-size-minx(AlibabaPuHuiTi,#333, 18px, normal);
          margin-bottom: 30px;
          &-dot {
            .size(6px, 6px);
            display: #display[inline-block];
            background-color: #006EFF;
            // border-radius: 50%;
            margin: 0 12px 2px 0;
          }
        }
        img {
          .size(420px);
          position: absolute;
        }
      }
    }
  }
  &-3 {
    .number-base(100%, 577px, 80px, #F6F9FF);
    &-h1 {
      .font-size-minx(AlibabaPuHuiTi, #333, 40px, 500);
      margin-bottom: 64px;
    }
    &-content {
      // .content-base(auto,flex, space-between, 1600px);
      .size(100%);
      display: #display[flex];
      justify-content: center;
      &-item {
        .size(228px, 307px);
        border-radius: 4px;
        border: 1px solid #E1EBFF;
        // padding: 4px;
        margin-left: 19px;
        display: #display[flex];
        justify-content: center;
        align-items: center;
        img {
          .size(220px, 299px);
        }
      }
    }
  }
}