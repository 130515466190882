@import url('../../index.less');
@import url('../Header/index.less');

.footer {
  .size(100%);
  &-1 {
    .number-base(100%, 333px, 58px);
    .background('https://s.huiyunban.cn/1744227070394765312.png');
    &-content {
      .content-base(auto, block);
      text-align: justify;
      &-h1 {
        .font-size-minx(AlibabaPuHuiTi, #3D3D3D, 50px, 500);
      }
      &-h2 {
        .font-size-minx(AlibabaPuHuiTi, #3D3D3D, 30px, normal);
        margin: 23px 0;
      }
      &-h3 {
        .nomal-button()
      }
    }
  }
  &-2 {
    .number-base(100%, 385px, 64px);
    background: #040D29;
    &-content {
      .content-base(auto, flex);
      text-align: justify;
      &-item-1 {
        .size(406px);
        &-logo {
          img {
            .size(207px, 60px)
          }
        }
        &-h1 {
          .font-size-minx(AlibabaPuHuiTi, #fff, 16px, normal);
          margin: 12px 0 33px 0;
        }
        &-content {
          .size(100%);
          display: flex;
          justify-content: space-between;
          &-i{
            .size(104px);
            &-title {
              .font-size-minx(AlibabaPuHuiTi, #fff, 16px, normal);
              margin-bottom: 21px;
            }
            img {
              .size(104px, 104px)
            }
          }
        }
      }
      &-item-2 {
        .size(524px);
        box-sizing: border-box;
        padding-left: 173px;
        &-h1 {
          .font-size-minx(AlibabaPuHuiTi, #fff, 20px, 500);
          margin-bottom: 27px;
          &::after {
            content: '';
            display: inline-block;
            .size(57px, 0);
            border: 2px solid #FDAE5A;
            position: absolute;
            top: 13px;
            margin-left: 5px;
          }
        }
        &-li {
          .font-size-minx(AlibabaPuHuiTi, #fff, 16px, normal);
          margin-bottom: 20px;
          img {
            .size(15px, 15px);
            margin-right: 13px;
          }
          a {
            color: #fff;
          }
        }
      }
      &-item-3 {
        .size(270px);
        box-sizing: border-box;
        padding-left: 123px;
      }
    }
  }
  &-3 {
    .number-base(100%, 80px, 29px);
    background: #161F37;
    .font-size-minx(AlibabaPuHuiTi, #fff, 16px, normal);
    a {
      color: #fff;
    }
  }
}