@import url('../../index.less');
@import url('../../Layout/Header/index.less');

.ai {
  .size(100%);
  &-1 {
    .number-base(100%, 375px, 119px);
    .background('https://s.huiyunban.cn/1742855338136178688.png');
    &-content {
      .content-base(auto, block, center);
      &-item {
        &:nth-child(1) {
          .font-size-minx(AlibabaPuHuiTi, #fff, 30px, normal);
          margin-bottom: 38px;
        }

        &:nth-child(2) {
          .font-size-minx(AlibabaPuHuiTi, #fff, 50px, 500);
        }
      }
    }
  }
  &-2 {
    .number-base(100%, 779px, 100px);
    &-h1 {
      .font-size-minx(AlibabaPuHuiTi, #3D3D3D, 40px, 500);
      margin-bottom: 79px;
    }
    &-content {
      .content-base(400px, flex);
      &-item {
        .size(400px, 450px);
        padding: 40px 32px 0 32px;
        box-sizing: border-box;
        background: #F5FAFF;
        &-title {
          .size(100%, 53px);
          text-align: justify;
          border-bottom: 1px solid #E1EBFF;
          .font-size-minx(AlibabaPuHuiTi, #333, 30px, 500);
          box-sizing: border-box;
          padding-left: 38px;
          margin-bottom: 30px;
          img {
            .size(27px, 27px);
            vertical-align: bottom;
            position: relative;
            top: -5px;
            margin-right: 10px;
          }
        }
        &-d {
          text-align: justify;
          margin-bottom: 20px;
          .font-size-minx(AlibabaPuHuiTi, #333, 24px, normal);
          img {
            .size(16px, 14px);
            margin: 0 22px 0 38px;
          }
        }
      }
    }
  }
  &-3 {
    .number-base(100%, 1185px, 90px, #F6F9FF);
    &-h1 {
      .font-size-minx(AlibabaPuHuiTi, #333, 40px, 500);
      margin-bottom: 80px;
    }
    &-content {
      .content-base(auto, flex);
      &-item {
        .size(384px, 418px);
        border-radius: 8px;
        text-align: justify;
        &:nth-child(1) {
          .background('https://s.huiyunban.cn/1744252071189680128.png');
        }
        &:nth-child(2) {
          .background('https://s.huiyunban.cn/1744252263683067904.png');
        }
        &:nth-child(3) {
          .background('https://s.huiyunban.cn/1744252441676746752.png');
        }
        &:nth-child(4) {
          .background('https://s.huiyunban.cn/1744252759386886144.png');
        }
        &:nth-child(5) {
          .background('https://s.huiyunban.cn/1744252966853939200.png');
        }
        &:nth-child(6) {
          .background('https://s.huiyunban.cn/1744253103907016704.png');
        }

        box-sizing: border-box;
        padding: 32px 27px 0 27px;
        &-h1 {
          .font-size-minx(normal, #fff, 30px, 500);
          margin-left: 177px;
        }
        &-h2 {
          .font-size-minx(normal, #333, 24px, normal);
          margin: 48px 0 35px 0;
        }
        &-h3 {
          .font-size-minx(normal, #555, 18px, normal);
          margin-bottom: 25px;
          margin-left: 6px;
        }
        &:nth-child(-n+3) {
          margin-bottom: 24px;
        }
      }
    }
  }
}