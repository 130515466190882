body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 1200px;
}

html {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.font-size-minx(@font-family, @color, @font-size: 'nomal', @font-weight: 'nomal') {
  font-family: @font-family;
  color: @color;
  font-size: @font-size;
  font-weight:  @font-weight;
  // line-height: @line-height;
}

.size(@width: auto, @height: auto) {
  width: @width;
  height: @height;
}

.background-i(@url, @cover: cover, @position: 50% 50%) {
  background: url(@url);
  background-repeat: no-repeat;
  background-size: @cover;
  background-position: @position;
}

.background(@url) {
  background: url(@url);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.number-base(@width, @height,@padding-top, @background:normal) {
  .size(@width, @height);
  padding-top: @padding-top;
  background: @background;
  box-sizing: border-box;
}

.content-base(@height, @display:flex, @justify-content: space-between, @width: 1200px) {
  .size(@width, @height);
  position: relative;
  left:  calc(50% - 600px);
  display: @display;
  justify-content: @justify-content;
  flex-wrap: wrap;
}

#position() {
  fixed: fixed;
  static: static;
  absolute: absolute;
  relative: relative;
}

#textAlign() {
  center: center;
  right: right;
  end: end;
  start: start;
  justify: justify;
}

#display() {
  flex: flex;
  block: block;
  inline: inline;
  inline-block: inline-block;
}